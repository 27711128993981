// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import helpers from "@/helpers";
import api from "@/services/api";
import { runInThisContext } from "vm";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";

const _ENDPONT = api.endpoint.CARRELLO.PACCHETTI_CATEGORIE;
@Component({
    computed: {
        ...mapState({
            width: (state: any) => state.width
        })
    }
})
export default class carrelloCategoriePage extends baseContextComponent {
    title: string = "Benvenuto in";
    subtitle: string = "My Webins";
    categorie: any = [];

    width!: number;

    created() {
        this.getData();
    }
    get categorieSorted(): Array<any> {
        if (this.categorie == null) return [];
        return this.categorie.sort((a, b) => (a.ordine > b.ordine ? 1 : -1));
    }
    mounted() {}

    getData() {
        var self = this;
        api.getDatiGriglia(`${_ENDPONT}`).then(res => {
            self.categorie = res;
        });
    }
}
